import fireExtImg from "../Images/Services/fire-extinguisher.png";
import fireAlarmImg from "../Images/Services/fire-alarm.png";
import firePumpImg from "../Images/Services/fire-pump.png";
import fireHydrantImg from "../Images/Services/fire-hydrant.png";
import ppeImg from "../Images/Services/ppe.png";
import fireSuprSysImg from "../Images/Services/fire-suppression-system.png";
import foamEquipImg from "../Images/Services/foam-equipment.png";
import firstAidKitImg from "../Images/Services/first-aid-kit.png";
import safteySignageImg from "../Images/Services/safety-signage.png";
import fireTruckImg from "../Images/Services/fire-truck.png";
import ambulanceImg from "../Images/Services/ambulance.png";
import coldStorageRoomImg from "../Images/Services/cold-storage-room.png";
import emergencyLightImg from "../Images/Services/emergency-light.png";
import securityImg from "../Images/Services/security-access-control.png";
import trainingImg from "../Images/Services/training-and-consultancy.png";
import msGiSheet from "../Images/Services/ms-gi-sheet.png";

export const products = [
  {
    image: fireExtImg,
    name: "Fire Extinguisher",
    shortDescription: "A comprehensive selection including water, foam, dry chemical, CO2, and clean agent extinguishers suitable for various fire classes.",
    longDescription: "A comprehensive selection of fire extinguishers includes water, foam, dry chemical, CO2, and clean agent types, each suitable for different fire classes. Water extinguishers are effective for Class A fires involving solid combustibles like wood and paper. Foam extinguishers are designed for Class A and B fires, particularly effective for flammable liquids such as oil and petrol. Dry chemical extinguishers are versatile, working on Class A, B, and C fires, making them ideal for flammable liquids, gases, and electrical equipment. CO2 extinguishers are commonly used for Class B and C fires, especially electrical fires, as they don't leave residue. Clean agent extinguishers, which include halocarbon-based chemicals, are perfect for sensitive equipment, such as electronics, and are safe for Class B and C fires, providing an effective and non-damaging solution.",
    keyOfferings : [
      "Water Extinguishers",
      "Foam Extinguishers",
      "Dry Chemical Extinguishers",
      "CO2 Extinguishers",
      "Clean Agent Extinguishers"
    ],
  },
  {
    image: fireAlarmImg,
    name: "Fire Alarm",
    shortDescription: "Advanced systems for smoke detection, heat detection, and gas detection, with integration capabilities for centralized monitoring.",
    longDescription: "A comprehensive range of advanced detection systems includes smoke, heat, and gas detectors, all with integration capabilities for centralized monitoring. Smoke detectors are designed to detect visible and invisible particles in the air, alerting occupants to the presence of fire. Heat detectors respond to temperature changes and are ideal for environments where smoke detectors may trigger false alarms, such as kitchens or dusty areas. Gas detectors are crucial for identifying hazardous gases like carbon monoxide, ensuring the safety of occupants by detecting leaks early. These systems can be integrated into centralized monitoring platforms, allowing for real-time alerts, data logging, and coordinated responses to potential threats.",
    keyOfferings: [
      "Smoke Detectors",
      "Heat Detectors",
      "Gas Detectors",
      "Centralized Monitoring Integration"
    ],
  },
  {
    image: firePumpImg,
    name: "Fire Pumps",
    shortDescription: "Our fire pumps ensure reliable, consistent performance and meet top industry standards. Available in electric, diesel, and jockey models to suit various needs.",
    longDescription: "Fire pumps are critical components of fire protection systems, designed to provide the necessary water pressure and flow to effectively combat fires. Our fire pumps are engineered for reliability and consistent performance, adhering to the highest industry standards for safety and efficiency. We offer a wide range of models, including electric, diesel, and jockey pumps, to meet the varied needs of our clients. Manufactured using high-quality materials and subjected to rigorous testing, our pumps ensure long-term durability and dependable performance. Additionally, we offer comprehensive technical support and maintenance services to guarantee the optimal functioning of all our fire pump systems.",
    keyOfferings: [
      "Electric Fire Pumps",
      "Diesel Fire Pumps",
      "Jockey Pumps",
      "High-Quality Materials",
      "Comprehensive Technical Support"
    ],
  },
  {
    image: fireHydrantImg,
    name: "Fire Hydrant",
    shortDescription: "Durable fire hydrants designed for tough conditions, with various models and accessories for comprehensive fire protection.",
    longDescription: "Fire hydrants are essential components of a fire protection system, offering a reliable water source for firefighting operations. Our fire hydrants are designed to endure harsh environmental conditions while meeting all relevant safety and performance standards. We provide a diverse selection of hydrant models, including dry-barrel, wet-barrel, and post-type hydrants, to accommodate various installation requirements. Alongside the fire hydrants, we also offer a comprehensive range of accessories, such as valves, couplings, and hose connections, ensuring a complete and integrated fire protection solution. Our team of experts is committed to delivering customized solutions and technical guidance to facilitate the optimal integration of our fire hydrants and accessories into your fire protection system.",
    keyOfferings: [
      "Dry-Barrel Hydrants",
      "Wet-Barrel Hydrants",
      "Post-Type Hydrants",
      "Valves and Couplings",
      "Hose Connections",
    ],
  },
  {
    image: ppeImg,
    name: "Personal Protective Equipment",
    shortDescription: "Complete range of PPE such as fire-resistant clothing, helmets, gloves, boots, and masks for firefighters and industrial workers.",
    longDescription: "We offer a complete range of personal protective equipment (PPE) designed for firefighters and industrial workers, including fire-resistant clothing, helmets, gloves, boots, and masks. Our PPE is engineered to provide maximum protection against heat, flames, and hazardous materials while ensuring comfort and mobility. Each item in our collection meets stringent safety standards, guaranteeing reliability in critical situations. The fire-resistant clothing is made from high-quality materials that resist ignition and offer excellent thermal protection. Our helmets are designed for durability and impact resistance, while the gloves and boots provide superior grip and dexterity. Additionally, our masks offer essential respiratory protection, ensuring that users can perform their duties safely and effectively.",
    keyOfferings: [
      "Fire-Resistant Clothing",
      "Safety Helmets",
      "Protective Gloves",
      "Durable Boots",
      "Respiratory Masks"
    ],
  },
  {
    image: emergencyLightImg,
    name: "Emergency Lights",
    shortDescription: "Battery-operated lights, along with illuminated safety signage, provide clear guidance to occupants during emergencies, ensuring visibility.",
    longDescription: "We provide a comprehensive selection of emergency exit signs, battery-operated lights, and illuminated safety signage designed to effectively guide occupants during emergencies. Our emergency exit signs are highly visible and meet all relevant safety standards, ensuring they can be easily spotted in low-light conditions. The battery-operated lights are reliable and provide essential illumination when power is lost, allowing for safe evacuation. Additionally, our illuminated safety signage is strategically designed to convey critical information quickly, directing individuals to exits and safe areas. Each product is engineered for durability and clarity, ensuring that occupants can navigate safely during any emergency situation.",
    keyOfferings: [
      "Emergency Exit Signs",
      "Battery-Operated Lights",
      "Illuminated Safety Signage"
    ],
  },
  {
    image: fireSuprSysImg,
    name: "Fire Suppression System",
    shortDescription: "Fixed fire suppression systems including sprinklers, water mist systems, and gas-based suppression systems for specialized applications.",
    longDescription: "We offer a complete range of fixed fire suppression systems, including sprinklers, water mist systems, and gas-based suppression systems, tailored for specialized applications. Our sprinkler systems are designed for rapid response to control and extinguish fires, providing effective coverage for various environments. Water mist systems utilize finely atomized water droplets, offering efficient cooling and fire suppression with minimal water usage, making them ideal for sensitive areas like data centers and museums. Additionally, our gas-based suppression systems, which include options such as FM-200 and CO2, are engineered for environments where water damage is a concern, providing effective fire control without compromising valuable assets. Each system is designed and installed to meet the highest safety standards, ensuring optimal performance in critical situations.",
    keyOfferings: [
      "Sprinkler Systems",
      "Water Mist Systems",
      "Gas-Based Suppression Systems"
    ],
  },
  {
    image: foamEquipImg,
    name: "Foam Equipment",
    shortDescription: "Class B foam works seamlessly with standard firefighting gear, including nozzles, monitors, foam tanks, and CAFS systems.",
    longDescription: "Class B foam is a versatile firefighting agent compatible with various conventional firefighting equipment, including low expansion non-air aspirating and air aspirating nozzles, monitors, medium expansion foam devices, Foam Bladder Tanks, and Compressed Air Foam Systems (CAFS). This compatibility enhances its effectiveness in extinguishing flammable liquid fires, creating a barrier between the fuel and the flames. Class B foam works by suppressing vapor and cooling the fire, making it an essential tool for both structural and industrial firefighting applications. Its ability to integrate seamlessly with existing equipment ensures that firefighters can utilize the foam efficiently in emergency situations.",
    keyOfferings: [
      "Compatible with Low Expansion Non-Air Aspirating Nozzles",
      "Compatible with Air Aspirating Nozzles",
      "Compatible with Monitors",
      "Compatible with Medium Expansion Foam Devices",
      "Compatible with Foam Bladder Tanks",
      "Compatible with Compressed Air Foam Systems (CAFS)"
    ],
  },
  {
    image: firstAidKitImg,
    name: "First Aid Kits",
    shortDescription: "Comprehensive first aid kits, trauma kits, and medical supplies compliant with safety standards for workplaces and homes.",
    longDescription: "We provide a comprehensive range of first aid kits, trauma kits, and medical supplies that comply with safety standards for both workplaces and homes. Our first aid kits are designed to address common injuries and emergencies, containing essential supplies such as bandages, antiseptics, and medical gloves. The trauma kits are equipped with advanced supplies to manage more severe injuries, including tourniquets, hemostatic dressings, and airway management tools. All of our medical supplies are carefully curated to ensure quality and reliability, making them suitable for various environments. Whether for a corporate office, construction site, or household use, our kits ensure that individuals are prepared to respond effectively in emergencies, promoting safety and well-being.",
    keyOfferings: [
      "Comprehensive First Aid Kits",
      "Advanced Trauma Kits",
      "Quality Medical Supplies",
      "Safety Standards Compliance",
      "Suitable for Workplaces and Homes"
    ],
  },
  {
    image: safteySignageImg,
    name: "Safety Signage",
    shortDescription: "Fire safety signs, evacuation maps, floor markings, and labels to ensure clear communication of safety procedures and emergency exits.",
    longDescription: "We offer a complete range of fire safety signs, evacuation maps, floor markings, and labels designed to ensure clear communication of safety procedures and emergency exits. Our fire safety signs are highly visible and comply with relevant regulations, providing essential information about fire hazards and safety protocols. Evacuation maps are strategically created to guide occupants to safe exits during emergencies, highlighting pathways and assembly points. Floor markings are designed to indicate safe routes and areas, ensuring that individuals can navigate effectively in critical situations. Additionally, our labels clearly identify emergency equipment, helping to facilitate quick responses during emergencies. Together, these elements create a comprehensive safety communication system that enhances preparedness and response in any environment.",
    keyOfferings: [
      "Fire Safety Signs",
      "Evacuation Maps",
      "Floor Markings",
      "Safety Labels",
      "Clear Communication of Safety Procedures"
    ],
  },
  {
    image: fireTruckImg,
    name: "Fire Trucks",
    shortDescription: "Our fire trucks are built to the highest safety standards, offering models like pumper trucks, and specialized vehicles for efficient firefighting operations.",
    longDescription: "As a comprehensive fire protection solutions provider, we offer a diverse range of fire trucks designed to meet the needs of our clients. Our fire trucks are built to the highest standards, ensuring reliable performance and superior safety features. We provide a wide selection of models, including pumper trucks, aerial trucks, and specialized vehicles, catering to the various requirements of fire departments and emergency response teams. Each fire truck is equipped with advanced features such as high-capacity water pumps, aerial ladders, and specialized equipment, enhancing the efficiency and effectiveness of firefighting operations. We collaborate closely with our clients to understand their specific operational needs and customize the fire truck configurations accordingly. Additionally, our team of experts offers comprehensive training and support to ensure the seamless integration of our fire trucks into our clients' emergency response capabilities.",
    keyOfferings: [
      "Pumper Trucks",
      "Aerial Trucks",
      "Specialized Fire Vehicles",
      "High-Capacity Water Pumps",
      "Aerial Ladders",
      "Customizable Configurations",
      "Comprehensive Training and Support"
    ],
  },
  {
    image: ambulanceImg,
    name: "Ambulance",
    shortDescription: "Ambulances built for safety and comfort, equipped with advanced medical features. Available in BLS and ALS models to meet various emergency needs.",
    longDescription: "Alongside our fire protection solutions, we offer a range of high-quality ambulances designed to meet the emergency medical needs of our clients. Patient comfort and safety are our top priorities, which is why our ambulances feature advanced life-support equipment and modern medical technologies. We provide a variety of ambulance models, including basic life support (BLS) and advanced life support (ALS) units, tailored to different emergency medical response requirements. Each ambulance is equipped with state-of-the-art features such as oxygen systems, cardiac monitoring devices, and advanced communication systems, ensuring the highest level of medical care during emergencies. Our team of experienced medical professionals and technicians works closely with clients to ensure proper training and maintenance of the ambulance fleet, optimizing performance and readiness. We are committed to continuously improving our ambulance offerings to remain at the forefront of the emergency medical transportation industry.",
    keyOfferings: [
      "High-Quality Ambulances",
      "Advanced Life-Support Equipment",
      "Basic Life Support (BLS) Units",
      "Advanced Life Support (ALS) Units",
      "Oxygen Systems",
      "Cardiac Monitoring Devices",
      "Advanced Communication Systems",
      "Proper Training and Maintenance"
    ]
  },
  {
    image: securityImg,
    name: "Security Access Control",
    shortDescription: "Integrated security solutions including access control systems, CCTV cameras, and intrusion detection systems to enhance safety measures.",
    longDescription: "We provide integrated security solutions designed to enhance safety measures across various environments. Our offerings include access control systems that regulate entry to secure areas, ensuring that only authorized personnel gain access. We also offer high-definition CCTV cameras that provide continuous monitoring and surveillance, helping to deter crime and capture critical incidents. Additionally, our intrusion detection systems are engineered to detect unauthorized access and alert security personnel in real-time, allowing for swift response to potential threats. By combining these advanced technologies, our integrated security solutions create a comprehensive safety framework that meets the specific needs of our clients.",
    keyOfferings: [
      "Access Control Systems",
      "High-Definition CCTV Cameras",
      "Intrusion Detection Systems",
      "Real-Time Alerts",
      "Comprehensive Safety Framework"
    ],
  },
  {
    image: coldStorageRoomImg,
    name: "Cold Storage Rooms",
    shortDescription: "Custom cold storage rooms and refrigeration systems for perishable goods, pharmaceuticals, and temperature-sensitive items.",
    longDescription: "We offer comprehensive services for the design, supply, and installation of cold storage rooms and refrigeration systems tailored to meet specific temperature and storage requirements. Our solutions are ideal for preserving perishable goods, pharmaceuticals, and other temperature-sensitive items. Each cold storage room is customized to ensure optimal environmental control, maintaining precise temperatures to safeguard product quality and integrity. Our refrigeration systems are designed for energy efficiency and reliability, ensuring consistent performance in critical storage environments. Whether for food storage, medical facilities, or industrial use, we provide end-to-end support, from initial consultation to final installation, ensuring that our clients' unique needs are fully met.",
    keyOfferings: [
      "Design of Cold Storage Rooms",
      "Supply of Refrigeration Systems",
      "Installation Services",
      "Customized Temperature Control",
      "Energy-Efficient Refrigeration",
      "Preservation of Perishable Goods",
      "Storage for Pharmaceuticals"
    ],
  },
  {
    image: trainingImg,
    name: "Training And Consultancy",
    shortDescription: "Fire safety training programs, evacuation drills, and consultancy services to educate and prepare organizations for emergency situations.",
    longDescription: "We provide comprehensive fire safety training programs, evacuation drills, and consultancy services aimed at educating and preparing organizations for emergency situations. Our training programs cover essential fire safety practices, including fire prevention, response strategies, and the proper use of firefighting equipment. We conduct realistic evacuation drills to ensure that employees are familiar with emergency protocols and can respond effectively during a crisis. Additionally, our consultancy services offer tailored assessments of existing safety measures, helping organizations identify potential risks and implement improvements. By equipping staff with the knowledge and skills necessary to handle emergencies, we enhance overall safety and preparedness within the workplace.",
    keyOfferings: [
      "Fire Safety Training Programs",
      "Realistic Evacuation Drills",
      "Consultancy Services",
      "Fire Prevention Strategies",
      "Response Strategies",
      "Assessment of Safety Measures",
      "Employee Preparedness"
    ]
  },
  {
    image: msGiSheet,
    name: "Premium MS & GI Sheet",
    shortDescription: "Get the job done right with our premium MS and GI sheets. We offer a comprehensive range of sizes in top-quality materials, ensuring durability and performance for any project.",
    longDescription: "Our comprehensive range of MS (Mild Steel), GI (Galvanized Iron) sheets, available in all sizes to meet the diverse needs of your projects. Whether you're tackling small-scale tasks or large industrial ventures, our materials are tailored to provide the perfect fit for any application.",
    keyOfferings: [
      "Comprehensive range",
      "All sizes available",
      "Suitable for small-scale tasks",
      "Suitable for large industrial ventures",
      "Tailored fit for any application"
    ]
  },
];
